import React from 'react';

import style from '../../pages/requests/style.module.scss';
import { ReactComponent as AppraisalSVG } from '../../images/icons/appraisal.svg';
import { ReactComponent as NewListingSVG } from '../../images/icons/new-listing.svg';
import { ReactComponent as MarketingSVG } from '../../images/icons/marketing.svg';
import { ReactComponent as GoLiveSVG } from '../../images/icons/go-live.svg';
import { ReactComponent as PriceAdjustmentSVG } from '../../images/icons/price-adjustment.svg';
import { ReactComponent as UnderOfferSVG } from '../../images/icons/under-offer.svg';
import { ReactComponent as OpenHomesSVG } from '../../images/icons/open-homes.svg';
import { ReactComponent as WithdrawPropertySVG } from '../../images/icons/withdraw-property.svg';
import { ReactComponent as FallOverSVG } from '../../images/icons/fall-over.svg';
import { ReactComponent as TrainingRequestSVG } from '../../images/icons/training-request.svg';
import { ReactComponent as ReportIssueSVG } from '../../images/icons/report-an-issue.svg';
import { ReactComponent as PromotionalProductsSVG } from '../../images/icons/promotional-products.svg';
import { ReactComponent as EquipmentBookingSVG } from '../../images/icons/equipment-booking.svg';
import { ReactComponent as LeaveRequestSVG } from '../../images/icons/leave-request.svg';
import { ReactComponent as RegistrationSVG } from '../../images/icons/registration-licence-upload.svg';
import { ReactComponent as PropertyBoostSVG } from '../../images/icons/rocket-launch-outline.svg';
import { ReactComponent as MagnifySVG } from '../../images/icons/magnify.svg';
import { ReactComponent as DrawPenSVG } from '../../images/icons/draw-pen.svg';
import { ReactComponent as OfficeBuildingPlus } from '../../images/icons/office-building-plus.svg';
import { CardLink } from '../../components/card-link';
import { useProjectTheme } from '../../hook/theme';
import { useMe } from '../../hook/use-me';
import { FileUploadType, ROLES_API } from '../../@types';
import { atomWithImmer } from 'jotai-immer';

//.

const IconsList: Record<string, JSX.Element> = {
  'AppraisalSVG': <AppraisalSVG />,
  'NewListingSVG': <NewListingSVG />,
  'MarketingSVG': <MarketingSVG />,
  'GoLiveSVG': <GoLiveSVG />,
  'PriceAdjustmentSVG': <PriceAdjustmentSVG />,
  'UnderOfferSVG': <UnderOfferSVG />,
  'OpenHomesSVG': <OpenHomesSVG />,
  'WithdrawPropertySVG': <WithdrawPropertySVG />,
  'FallOverSVG': <FallOverSVG />,
  'TrainingRequestSVG': <TrainingRequestSVG />,
  'ReportIssueSVG': <ReportIssueSVG />,
  'PromotionalProductsSVG': <PromotionalProductsSVG />,
  'EquipmentBookingSVG': <EquipmentBookingSVG />,
  'LeaveRequestSVG': <LeaveRequestSVG />,
  'RegistrationSVG': <RegistrationSVG />,
  'PropertyBoostSVG': <PropertyBoostSVG />,
  'MagnifySVG': <MagnifySVG />,
  'DrawPenSVG': <DrawPenSVG />,
  'OfficeBuildingPlus': <OfficeBuildingPlus />
};

interface TechStackProps {
  image: string;
  title: string;
  href: string;
}
function TechStack({ image, title, href }: TechStackProps) {
  return (
    <a className={style.techStackImg} href={href} target={'_blank'} rel={'noreferrer'}>
      <img src={`/images/tech-stack/${image}`} width={140} height={50} alt={title} />
    </a>
  );
}

export function Requests() {
  const { data: theme } = useProjectTheme();
  const { data: me } = useMe();

  const state = me?.state;

  const techStack = theme?.requests.techStack;

  if (theme) {
    const items = theme.requests.items;
    const description = theme.requests.description;
    const intro = theme.requests.intro;

    return (
      <div data-testid={'home'} className={'d-flex flex-column align-items-center'}>
        {intro && <p className={'text-center pre-wrap mb-5'}>{intro}</p>}

        <div className={'d-flex justify-content-center align-items-center w-75 flex-wrap gap-3'}>
          {techStack?.map(tech => {
            const { title, image, url } = tech;

            let href;
            if (typeof url === 'string') href = url;
            else {
              // URL is an object with [key: url]. Each key can be attached to a condition.
              // For example, we are able to get a URL for each state, or whatever we need. Like {WA: url}.

              if (state && url[state]) href = url[state];
            }

            if (tech.roles && !me?.roles.includes(ROLES_API.ROLE_ADMIN)) {
              if (!me?.roles.some(role => tech.roles?.includes(role))) return null;
            }

            if (tech.states && me?.state && !tech.states.includes(me.state)) {
              return null;
            }

            return href ? <TechStack key={title} image={image} title={title} href={href} /> : null;
          })}
        </div>

        {description && <p className={'text-center pre-wrap'}>{description}</p>}

        <div className={`${style.grid} mt-5`}>
          {items.map(item => {
            const { link, disabled, title, icon, hide } = item.tile;

            if (hide) return null;

            if (link.includes('http')) {
              return <CardLink id={item.tile.title} key={title} href={link} label={title} Icon={IconsList[icon]} disabled={disabled} />;
            } else {
              return <CardLink id={item.tile.title} key={title} to={link} label={title} Icon={IconsList[icon]} disabled={disabled} />;
            }
          })}
        </div>
      </div>
    );
  }

  return <></>;
}

export const filesAtom = atomWithImmer<FileUploadType[]>([]);
