import { Accordion, Nav, Offcanvas } from 'react-bootstrap';
import React, { createContext, PropsWithChildren, useState } from 'react';
import { Logo } from '../logo';
import { NavLink } from 'react-router-dom';
import { useMe } from '../../hook/use-me';
import { ROLES_API } from '../../@types';
import { UserZod } from '../../schemas/user.zod';
import { ReactComponent as AppraisalSVG } from '../../images/icons/appraisal.svg';
import { ReactComponent as NewListingSVG } from '../../images/icons/new-listing.svg';
import { ReactComponent as GoLiveSVG } from '../../images/icons/go-live.svg';
import { ReactComponent as PriceAdjustmentSVG } from '../../images/icons/price-adjustment.svg';
import { ReactComponent as OpenHomesSVG } from '../../images/icons/open-homes.svg';
import { ReactComponent as WithdrawPropertySVG } from '../../images/icons/withdraw-property.svg';
import { ReactComponent as FallOverSVG } from '../../images/icons/fall-over.svg';
import { ReactComponent as UnderOfferSVG } from '../../images/icons/under-offer.svg';

import style from './style.module.scss';
import { useAtom } from 'jotai/index';
import { embedAtom } from '../../pages/Bootstrap';

type SidebarContextType = {
  openSidebar: () => void;
  closeSidebar: () => void;
};
export const SidebarContext = createContext<SidebarContextType>({ openSidebar: () => null, closeSidebar: () => null });

//.

type SidebarProps = {
  startsOpened?: boolean;
};
export function Sidebar({ startsOpened = false, children }: PropsWithChildren<SidebarProps>) {
  const { data: me } = useMe();

  // if (!me) return <></>;

  return (
    <SidebarContent startsOpened={startsOpened} user={me}>
      {children}
    </SidebarContent>
  );
}

interface SidebarContentProps {
  user?: UserZod;
  startsOpened: boolean;
}
function SidebarContent({ user, startsOpened, children }: PropsWithChildren<SidebarContentProps>) {
  const [show, setShow] = useState(startsOpened);
  const [embed] = useAtom(embedAtom);

  const openSidebar = () => setShow(true);
  const closeSidebar = () => setShow(false);

  const rex = user?.userRex?.rexAccount !== null;

  const logo = embed ? null : <Logo height={22} />;

  return (
    <SidebarContext.Provider value={{ openSidebar, closeSidebar }}>
      <Offcanvas show={show} placement={'end'} onHide={closeSidebar} className={style.offcanvas} data-testid={'sidebar'}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {logo}
            <p className={style.user}>
              {user?.firstName} {user?.lastName}
            </p>
            {rex && <em className={'fs-7 text-gray-2'}>Connected to Rex</em>}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav defaultActiveKey="/" className="flex-column" onSelect={closeSidebar}>
            <Nav.Link eventKey="link-0" as={NavLink} to={'/requests'}>
              Requests
            </Nav.Link>
            <Nav.Link eventKey="link-1" as={NavLink} to={'/dashboard'}>
              Dashboard
            </Nav.Link>
            <Nav.Link eventKey="link-2" as={NavLink} to={'/profile'}>
              Profile
            </Nav.Link>
            {user?.roles.find(role => role === ROLES_API.ROLE_ADMIN || role === ROLES_API.ROLE_SUPPORT || role === ROLES_API.ROLE_SUPER_ADMIN) && (
              <Nav.Link eventKey="link-3" as={NavLink} to={'/users'}>
                Users
              </Nav.Link>
            )}
            {user?.roles.find(role => role === ROLES_API.ROLE_ADMIN || role === ROLES_API.ROLE_SUPPORT || role === ROLES_API.ROLE_SUPER_ADMIN) && (
              <Nav.Link eventKey="link-4" as={NavLink} to={'/teams'}>
                Teams
              </Nav.Link>
            )}

            <Accordion className={style.accordion}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Requests</Accordion.Header>
                <Accordion.Body>
                  <Nav.Link eventKey="link-6" as={NavLink} to={'/requests/appraisal'}>
                    <AppraisalSVG />
                    Appraisals
                  </Nav.Link>
                  <Nav.Link eventKey="link-7" as={NavLink} to={'/requests/new-listing'}>
                    <NewListingSVG />
                    New Listing
                  </Nav.Link>
                  <Nav.Link eventKey="link-8" as={NavLink} to={'/requests/go-live'}>
                    <GoLiveSVG />
                    Go Live
                  </Nav.Link>
                  <Nav.Link eventKey="link-9" as={NavLink} to={'/requests/price-adjustment'}>
                    <PriceAdjustmentSVG />
                    Price Adjustment
                  </Nav.Link>
                  <Nav.Link eventKey="link-10" as={NavLink} to={'/requests/under-offer'}>
                    <UnderOfferSVG />
                    Under Offer
                  </Nav.Link>
                  <Nav.Link eventKey="link-11" as={NavLink} to={'/requests/open-homes'}>
                    <OpenHomesSVG />
                    Open Homes
                  </Nav.Link>
                  <Nav.Link eventKey="link-12" as={NavLink} to={'/requests/withdraw-property'}>
                    <WithdrawPropertySVG />
                    Withdraw Property
                  </Nav.Link>
                  <Nav.Link eventKey="link-13" as={NavLink} to={'/requests/fall-over'}>
                    <FallOverSVG />
                    Fall Over
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Nav.Link eventKey="link-18" as={NavLink} to={'/login/logout'} className={'mt-5'}>
              Logout
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
      {children}
    </SidebarContext.Provider>
  );
}
