import React from 'react';
import { Stack } from 'react-bootstrap';
import style from './style.module.scss';
import { Logo } from '../../components/logo';
import { Outlet } from 'react-router-dom';
import { SuspenseLoader } from '../../components/suspense-loader';
import { useProjectTheme } from '../../hook/theme';
import { useAtom } from 'jotai';
import { embedAtom } from '../Bootstrap';

//.

export function Login() {
  const { data: theme, isFetching: isFetchingTheme, error: errorTheme } = useProjectTheme();
  const [embed] = useAtom(embedAtom);

  let content;
  if (isFetchingTheme) content = <SuspenseLoader fullscreen />;
  else if (errorTheme) content = <p>There was an error loading the theme: {JSON.stringify(errorTheme)}</p>;
  else if (theme && !embed)
    content = (
      <Stack className={'align-items-center'}>
        <Logo width={400} />
      </Stack>
    );

  return (
    <main className={`${style.login} d-flex align-items-center justify-content-center`}>
      <div>
        {content}
        <Outlet />
      </div>
    </main>
  );
}
